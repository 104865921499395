<template>
  <div>
    <ResumeRoom style="margin-bottom: 2%;"></ResumeRoom>
    <v-card>
      <v-tabs
        v-model="tab"
        centered
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Libre
          <v-icon>mdi-phone</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Occuper
          <v-icon>mdi-heart</v-icon>
        </v-tab>

        <v-tab href="#tab-3">
          Hors Service
          <v-icon>mdi-account-box</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          :value="'tab-' + 1"
        >
          <v-row style="margin-top: 2%;padding-left: 1%;padding-right: 1%;">
            <v-col
              v-for="room in room_avaible"
              :key="room.id"
              class="align-self-start"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card>
                <v-img
                  :src="photosRoom(room.room_photos)[0]"
                  height="250"
                ></v-img>
                <v-card-title>
                  {{ room.room_name }}
                </v-card-title>
                <v-card-text>
                  Prix: {{ room.room_total_price }} Type: {{ room.room_type.room_type_name }}
                </v-card-text>
                <v-card-actions class="dense">
                  <v-btn
                    color="primary"
                    text
                  >
                    Details
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="room.show_detail = !room.show_detail"
                  >
                    <v-icon>
                      {{
                        room.show_detail ? icons.mdiChevronUp : icons.mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="room.show_detail">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <div class="pt-5">
                          <div class="text-center mb-3">
                            <v-btn
                              color="primary"
                              depressed
                              @click="showEditRoomDialog(room)"
                            >
                              Mettre à jour &ensp;
                              <v-icon>
                                {{ icons.mdiCommentEditOutline }}
                              </v-icon>
                            </v-btn>
                          </div>
                          <div>
                            <span class="big-title mark uppercase">Code &emsp; </span>
                            <span class="big-title mark important">{{ room.room_name }}</span>
                          </div>
                          <div>
                            <span class="big-title mark uppercase">Prix &emsp; </span>
                            <span class="big-title important mark">{{ room.room_unit_price }} FCFA</span>
                          </div>
                          <div>
                            <span class="big-title mark uppercase">Apartement &emsp; </span>
                            <span class="big-title important mark">{{ room.apartment.apart_bloc }}</span>
                          </div>
                          <div>
                            <span class="big-title mark uppercase d-block">Date de création </span>
                            <span class="big-title important mark">{{ room.room_create_date }}</span>
                          </div>
                          <div>
                            <span class="big-title mark uppercase">Type de chambre &emsp; </span>
                            <span class="big-title important mark">{{ room.room_type.room_type_name }}</span>
                          </div>
                          <div>
                            <span class="big-title mark uppercase">Toutes les images &emsp; </span>
                            <span
                              class="big-title important mark d-block d-flex justify-space-between align-content-center"
                            >
                              <v-btn
                                color="primary"
                                depressed
                                @click="showMore(room.room_photos)"
                              >
                                Voir&ensp;<v-icon>
                                  {{ icons.mdiEye }}
                                </v-icon>
                              </v-btn>
                            </span>
                          </div>
                        </div>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          :value="'tab-' + 2"
        >
          <v-row style="margin-top: 2%;padding-left: 1%;padding-right: 1%;">
            <v-col
              v-for="room in room_buzy"
              :key="room.id"
              class="align-self-start"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card>
                <v-img
                  :src="photosRoom(room.room_photos)[0]"
                  height="250"
                ></v-img>
                <v-card-title>
                  {{ room.room_name }}
                </v-card-title>
                <v-card-text>
                  Prix: {{ room.room_total_price }} Type: {{ room.room_type.room_type_name }}
                </v-card-text>
                <v-card-actions class="dense">
                  <v-btn
                    color="primary"
                    text
                  >
                    Details
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="isCardDetailsVisible = !isCardDetailsVisible"
                  >
                    <v-icon>
                      {{
                        isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="isCardDetailsVisible">
                    <v-divider></v-divider>
                    <v-card-text>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          :value="'tab-' + 3"
        >
          <v-row style="margin-top: 2%;padding-left: 1%;padding-right: 1%;">
            <v-col
              v-for="room in room_off"
              :key="room.id"
              class="align-self-start"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card>
                <v-img
                  :src="photosRoom(room.room_photos)[0]"
                  height="250"
                ></v-img>
                <v-card-title>
                  {{ room.room_name }}
                </v-card-title>
                <v-card-text>
                  Prix: {{ room.room_total_price }} Type: {{ room.room_type.room_type_name }}
                </v-card-text>
                <v-card-actions class="dense">
                  <v-btn
                    color="primary"
                    text
                  >
                    Details
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="isCardDetailsVisible = !isCardDetailsVisible"
                  >
                    <v-icon>
                      {{
                        isCardDetailsVisible ? icons.mdiChevronUp : icons.mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="isCardDetailsVisible">
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div>
      <v-dialog
        v-model="editRoomDialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <v-toolbar
            class="p-0"
            color="primary"
            dark
            flat
          >
            <v-btn
              dark
              icon
              @click="closeEditRoomDialog"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-toolbar-title class="text-uppercase">
              Modification de chambre
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pt-3">
              <v-btn
                class="text-uppercase"
                color="rgba(198, 48, 49,0.1)"
                dark
                @click="closeEditRoomDialog"
              >
                Fermer
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-form
              class="multi-col-validation"
              @submit.prevent="formSubmit($event.target,400)"
            >
              <v-row
                class="pa-10"
              >
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="currentRoom.room_name"
                    dense
                    hide-details
                    label="Numéro de la chambre"
                    name="room_name"
                    outlined
                    placeholder="Numéro"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="currentRoom.room_create_date"
                    dense
                    hide-details
                    label="Date de création"
                    name="room_create_date"
                    outlined
                    placeholder="Création"
                    required
                    type="date"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="currentRoom.room_unit_price"
                    dense
                    hide-details
                    label="Prix unitaire"
                    name="room_unit_price"
                    outlined
                    placeholder="prix"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="currentRoom.room_total_price"
                    dense
                    hide-details
                    label="Prix de vente"
                    name="room_total_price"
                    outlined
                    placeholder="prix de vente"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="currentRoom.room_type_id"
                    :item-text="'room_type_name'"
                    :item-value="'id'"
                    :items="roomtypes"
                    dense
                    label="Type de chambre"
                    name="room_type_id"
                    outlined
                    required
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="currentRoom.apartment_id"
                    :item-text="'apart_bloc'"
                    :item-value="'id'"
                    :items="apartments"
                    dense
                    label="Block d'appartement concerné"
                    name="apartment_id"
                    outlined
                    required
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    :value="defaultRoomInputFileValue"
                    dense
                    hide-details
                    label="Images de la chambre"
                    multiple
                    name="room_photos[]"
                    outlined
                    placeholder="Selectionner les photos de la chambres"
                    required
                    type="file"
                  ></v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="currentRoom.room_state"
                    :item-text="'label'"
                    :item-value="'code'"
                    :items="roomStatus"
                    dense
                    label="Etat"
                    name="room_state"
                    outlined
                    required
                  ></v-select>
                </v-col>
                <v-col
                  class="d-flex justify-center align-center"
                  cols="12"
                  md="12"
                >
                  <v-radio-group
                    v-model="imagesAction"
                    name="file_action"
                    row
                  >
                    <v-radio
                      :value="200"
                      class="mb-2"
                      label="Aucune modification d'image"
                    ></v-radio>
                    <v-radio
                      :value="202"
                      class="mb-2"
                      label="Modifier toutes les images"
                    ></v-radio>
                    <v-radio
                      :value="204"
                      class="mb-2"
                      label="Ajouter les nouvelles images aux anciennes"
                    ></v-radio>
                    <v-radio
                      :value="203"
                      class="mb-2"
                      label="Modifier une image"
                      @click="selectImageToUpdateDialog=!selectImageToUpdateDialog"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    type="submit"
                  >
                    Soumettre
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    outlined
                    @click="closeEditRoomDialog"
                  >
                    Annuler
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="selectImageToUpdateDialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <v-toolbar
            class="p-0"
            color="primary"
            dark
            flat
          >
            <v-btn
              dark
              icon
              @click="selectImageToUpdateDialog = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-toolbar-title class="text-uppercase">
              Selectionner une image
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pt-3">
              <v-btn
                class="text-uppercase"
                color="rgba(198, 48, 49,0.1)"
                dark
                @click="selectImageToUpdateDialog = false"
              >
                Fermer
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row
                class="d-flex justify-center align-center"
                dense
              >
                <v-radio-group
                  v-model="imageToUpdate"
                  row
                >
                  <v-col
                    v-for="(image,index) in photosRoom(currentRoom.room_photos)"
                    :key="index"
                    md="4"
                    sm="6"
                    style="width: 300px"
                  >
                    <v-card>
                      <v-img
                        :src="image"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="400px"
                      />
                      <v-card-actions class="d-flex justify-center align-center pa-3">
                        <v-radio
                          :value="image"
                        ></v-radio>
                        <v-btn
                          color="primary"
                          depressed
                          @click="showSigleImage(image)"
                        >
                          Voir&ensp;<v-icon>
                            {{ icons.mdiEye }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-radio-group>
              </v-row>
            </v-container>
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiCartPlus,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCommentEditOutline,
  mdiEye,
  mdiHelpCircleOutline,
  mdiLockOpenOutline,
  mdiShareVariantOutline,
  mdiStarOutline,
  mdiTrendingUp,
} from '@mdi/js'
// eslint-disable-next-line import/extensions,import/no-unresolved
import ResumeRoom from '@/views/rooms/ResumeRoom'
import * as components from 'vuetify'

export default {
  name: 'RoomList',
  components: {
    ResumeRoom,
  },
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,
      tab: null,
      text: 'en cours de dev',

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiEye,
        mdiCommentEditOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      list_room: [],
      room_avaible: [],
      currentRoom: {},
      tempCurrentRoom: {},
      editRoomDialog: false,
      defaultRoomInputFileValue: undefined,
      room_buzy: [],
      room_off: [],
      apartments: [],
      roomtypes: [],
      roomStatus: [
        {
          label: 'En service',
          code: 30,
        },
        {
          label: 'Hors service',
          code: 500,
        },
        {
          label: 'Occupé',
          code: 405,
        },
      ],
      selectImageToUpdateDialog: false,
      imagesAction: 200,
      imageToUpdate: '',
    }
  },
  mounted() {
    this.loadRoom()
    this.loadApartments()
    this.loadRoomTypes()
  },
  methods: {
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoom).then(rp => {
        this.roomSetData(rp.data)
      })
    },
    roomSetData(data) {
      this.list_room = []
      data.forEach(r => {
        const tempRoom = r
        tempRoom.show_detail = false
        this.list_room.push(tempRoom)
      })
      this.room_avaible = this.list_room.filter(room => room.room_status === 400)
      this.room_buzy = this.list_room.filter(room => room.room_status === 405)
      this.room_off = this.list_room.filter(room => room.room_status === 500)
    },
    loadApartments() {
      this.$axios.get(this.$endpoint.LoadApartment).then(rp => {
        this.apartments = rp.data
      })
    },
    loadRoomTypes() {
      this.$axios.get(this.$endpoint.LoadRoomType).then(rp => {
        this.roomtypes = rp.data
      })
    },
    photosRoom(imgtable) {
      const finaltable = []
      if (imgtable) {
        const source = imgtable.split(';')
        source.forEach(src => {
          finaltable.push(`${process.env.VUE_APP_API_ASSET_URL}${src}`)
        })
      }

      return finaltable
    },
    showMore(roomImages) {
      this.$viewerApi({
        images: this.photosRoom(roomImages),
      })
    },
    showSigleImage(image) {
      const images = [image]
      this.$viewerApi({
        images,
      })
    },
    showEditRoomDialog(room) {
      this.defaultRoomInputFileValue = new File([], '', undefined)
      this.editRoomDialog = !this.editRoomDialog
      this.imagesAction = 200
      this.currentRoom = room
    },
    closeEditRoomDialog() {
      this.loadRoom()
      this.editRoomDialog = false
    },
    formSubmit(e, roomStatus) {
      const f = new FormData(e)

      // // eslint-disable-next-line no-restricted-syntax
      // for (const pair of f.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      const getActionAccordingRoomStatus = (status, rp) => {
        const ob = {
          400: r => {
            this.roomSetData(r.data.rooms)
            this.editRoomDialog = false
          },
        }

        return (ob[status](rp) || ob[400](rp))
      }
      this.$axios.post(this.$endpoint.UpdateRoom(this.currentRoom.id), f).then(rp => getActionAccordingRoomStatus(roomStatus, rp))
    },
  },
}
</script>
<style scoped>
.v-toolbar {
  flex: none;
}

.viewer-button {
  z-index: 99999;
}
</style>
