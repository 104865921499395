<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Récapitulatif des chambre</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Total {{ list_room.length }} Chambres</span>
<!--      <span>😎 this month</span>-->
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiHomeLock, mdiHomeLockOpen, mdiHomeRemove, mdiHome } from '@mdi/js'

export default {
  name: 'ResumeRoom',
  data() {
    return {
      list_room: [],
      statisticsData: [
        {
          title: 'Occuper',
          total: 0,
        },
        {
          title: 'Libre',
          total: 0,
        },
        {
          title: 'Hors Service',
          total: 0,
        },
        {
          title: 'En Service',
          total: 0,
        },
      ],
    }
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Occuper') return { icon: mdiHomeLock, color: 'primary' }
      if (data === 'Libre') return { icon: mdiHomeLockOpen, color: 'success' }
      if (data === 'Hors Service') return { icon: mdiHomeRemove, color: 'warning' }
      if (data === 'En Service') return { icon: mdiHome, color: 'info' }

      return { icon: mdiHomeLockOpen, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiHomeLockOpen,
        mdiHome,
        mdiHomeLock,
        mdiHomeRemove,
      },
    }
  },
  mounted() {
    this.loadRoom()
  },
  methods: {
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoom).then(rp => {
        this.list_room = rp.data
        this.statisticsData[0].total = this.list_room.filter(room => room.room_status === 450).length
        this.statisticsData[1].total = this.list_room.filter(room => room.room_status === 400).length
        this.statisticsData[2].total = this.list_room.filter(room => room.room_state === 0).length
        this.statisticsData[3].total = this.list_room.filter(room => room.room_state === 30).length
      })
    },

  },
}
</script>
